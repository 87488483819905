import logo from './dementx.gif';
import './App.css';

function App() {
  return (
    <div className="App">

        <img src={logo} alt="logo" />
    </div>
  );
}

export default App;
